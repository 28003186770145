import React, { useState } from 'react';
import { Home, Code, Mail, Github, Linkedin, User, Menu, X } from 'lucide-react';
import logo from './logo.png';

const styles = `
  @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Roboto:wght@300;400;700&display=swap');

  body {
    font-family: 'Roboto', sans-serif;
  }

  h1, h2, h3 {
    font-family: 'Playfair Display', serif;
  }

  .hover-animate {
    transition: transform 0.2s ease-in-out;
  }
  .hover-animate:hover {
    transform: scale(1.03);
  }
  .hover-subtle:hover {
    opacity: 0.8;
    transition: opacity 0.2s ease-in-out;
  }
  .bg-pattern {
    background-color: #f3f4f6;
    background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239CA3AF' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  @media (max-width: 640px) {
    .mobile-menu {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: white;
      padding: 1rem;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    }
  }
`;

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-white bg-opacity-90 p-4 sticky top-0 z-10 shadow-md">
      <div className="container mx-auto">
        <nav className="flex justify-between items-center">
          <div className="flex items-center">
            <img src={logo} alt="Logo" className="h-8 w-8 mr-2" /> {/* Adjust size as needed */}
            <div className="text-2xl font-bold text-gray-800 hover-animate font-serif">Matthew Aragaw</div>
          </div>
          <div className="sm:hidden">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
          <ul className={`sm:flex space-y-2 sm:space-y-0 sm:space-x-4 ${isMenuOpen ? 'mobile-menu' : 'hidden sm:flex'}`}>
            {['Home', 'Skills', 'Contact'].map((item, index) => (
              <li key={index}>
                <a href={`#${item.toLowerCase()}`} className="flex items-center text-gray-600 hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium hover-subtle" onClick={() => setIsMenuOpen(false)}>
                  {item === 'Home' && <Home className="mr-1" size={18} />}
                  {item === 'Skills' && <Code className="mr-1" size={18} />}
                  {item === 'Contact' && <Mail className="mr-1" size={18} />}
                  {item}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

const HomePage = () => (
  <div id="home" className="container mx-auto px-4 py-8">
    <h1 className="text-4xl sm:text-5xl font-bold mb-4 flex items-center text-gray-800 hover-animate">
      <User className="mr-2" size={36} /> Matthew Aragaw
    </h1>
    <p className="text-lg sm:text-xl mb-4 text-gray-600 font-light">ML Architectures + Models | Backend + Infrastructure Systems & Web | Operating Systems | Embedded | Cloud</p>
    <div className="mb-6 text-gray-700 leading-relaxed">
      <p className="mb-3">
        Software Engineer with a fascination for complex systems and artificial intelligence. Focused on developing robust, scalable solutions that leverage cutting-edge systems and AI technologies to solve real-world problems.
      </p>
      <p className='mb-3'>Developing scalable, extensible systems. Currently Software Engineer @ Amazon </p>
      <p className='italic'>
        Strive to bridge the gap between theoretical concepts and practical applications. 
      </p>
    </div>
  </div>
);

const Skills = () => {
  const skillCategories = [
    {
      category: "ML Architectures + Models",
      skills: ["TensorFlow", "PyTorch", "Keras", "Scikit-learn", "Deep Learning", "Computer Vision", "NLP"]
    },
    {
      category: "Backend + Infrastructure Systems & Web",
      skills: ["Node.js", "Express.js", "Django", "Flask", "RESTful APIs", "GraphQL", "Docker", "Kubernetes", "AWS", "Google Cloud", "Microservices"]
    },
    {
      category: "Operating Systems",
      skills: ["Linux", "Unix", "Windows", "macOS", "Shell Scripting", "System Programming", "Virtualization"]
    },
    {
      category: "Embedded Systems",
      skills: ["Arduino", "Raspberry Pi", "RTOS", "C for Embedded", "ARM", "IoT Protocols", "Firmware Development"]
    },
    {
      category: "Programming Languages",
      skills: ["Python", "C++", "Java", "JavaScript", "TypeScript", "Go", "Rust"]
    },
    {
      category: "Tools & Methodologies",
      skills: ["Git", "CI/CD", "Agile", "TDD", "Design Patterns", "Data Structures", "Algorithms"]
    }
  ];

  return (
    <div id="skills" className="container mx-auto px-4 py-8">
      <h2 className="text-3xl sm:text-4xl font-bold mb-6 flex items-center text-gray-800 hover-animate">
        <Code className="mr-2" size={30} /> Skills
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {skillCategories.map((category, index) => (
          <div key={index} className="mb-6">
            <h3 className="text-xl font-semibold mb-2 text-gray-700">{category.category}</h3>
            <ul className="flex flex-wrap text-gray-600">
              {category.skills.map((skill, skillIndex) => (
                <li key={skillIndex} className="hover-subtle mr-3 mb-2 text-sm sm:text-base bg-gray-200 rounded-full px-3 py-1">
                  {skill}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

const Contact = () => (
  <div id="contact" className="container mx-auto px-4 py-8">
    <h2 className="text-3xl sm:text-4xl font-bold mb-4 flex items-center text-gray-800 hover-animate">
      <Mail className="mr-2" size={30} /> Contact
    </h2>
    <p className="mb-4 text-gray-600">Feel free to reach out to me:</p>
    <ul className="space-y-2">
      {[
        { icon: Mail, text: 'maragaw24@gmail.com', href: 'mailto:maragaw24@gmail.com' },
        { icon: Github, text: 'GitHub', href: 'https://github.com/maragaw' },
        { icon: Linkedin, text: 'LinkedIn', href: 'https://www.linkedin.com/in/matthew-aragaw/' }
      ].map((item, index) => (
        <li key={index} className="flex items-center">
          <item.icon className="mr-2 text-gray-600" size={18} />
          <a href={item.href} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline hover-subtle text-base sm:text-lg">
            {item.text}
          </a>
        </li>
      ))}
    </ul>
  </div>
);

const Footer = () => (
  <footer className="bg-white bg-opacity-90 text-gray-600 p-4 mt-8">
    <div className="container mx-auto">
      <p>&copy; 2024 Matthew Aragaw. All rights reserved.</p>
    </div>
  </footer>
);

const App = () => (
  <div className="flex flex-col min-h-screen bg-pattern">
    <style>{styles}</style>
    <Header />
    <main className="flex-grow">
      <HomePage />
      <Skills />
      <Contact />
    </main>
    <Footer />
  </div>
);

export default App;